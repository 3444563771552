import React from "react";
import { graphql } from "gatsby";
import styled from 'styled-components';
import Layout from "../components/layout";
import SEO from "../components/seo";

const StyledWrapper = styled.div`
  font-size: 18px;;
  font-weight: bold;
  
  h1 {
    text-align: center;
    width: 100%;
    display: block;
  }

  label {
    display: block;
  }

  form {
    padding-left: 1rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    line-height: 1.8rem;
    padding: 6px;
  }

  [type="submit"] {
    all: unset;
    background: #dd4a68;
    color: white;
    padding: .75rem 1rem;
    border-radius: 20px;

    &:hover {
      background: #d33253;
      transition: background-color 200ms ease;
    }
  }

  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    max-width: 60vw;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;
    flex-basis: 46%;
    justify-content: space-between;
  }
`

const Contact = ({ data, location }) => {

  return (
    <Layout location={location} title="Newtown Hair">
      <SEO title="Contact" />
      <StyledWrapper>
        <h1>Contact</h1>
      </StyledWrapper>

      <StyledWrapper>

        <div>
          <p style={{ paddingLeft: '1rem' }}>Home Salon located in central Torquay- Exact Location given in appointment confirmation text.</p>

          <p style={{ paddingLeft: '1rem' }}>
            Phone: <a href="tel:+61352296876">(03) 5229 6876</a>
          </p>

          <p style={{ paddingLeft: '1rem' }}>
            <a href="https://www.instagram.com/sonia_newtownhair/?hl=en">View Newtown Hair on Instagram</a>
          </p>

          <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <p>
              <label for="input-name">Name:</label>
              <input type="text" id="input-name" name="name" />
            </p>
            <p>
              <label for="input-email">Email:</label>
              <input type="email" id="input-email" name="email" />
            </p>
            <p>
              <label for="input-phone">Phone:</label>
              <input type="text" id="input-phone" name="phone" />
            </p>
            <p>
              <label for="input-message">Message:</label>
              <textarea name="message" id="input-message"></textarea>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </div>
      </StyledWrapper>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
